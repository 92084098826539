import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const RedirectRoot = ({ path }) => <Navigate to={path} />;

RedirectRoot.propTypes = {
  path: PropTypes.string.isRequired,
};

export default RedirectRoot;
