import clsx from 'clsx';
import PropTypes from 'prop-types';

function ArrowIcon({ className = '', ...props }) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx('w-6 h-6', className)}
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M7.828 11H20v2H7.828l5.364 5.364-1.414 1.414L4 12l7.778-7.778 1.414 1.414L7.828 11Z" fill="#9B9DAE" />
    </svg>
  );
}

ArrowIcon.propTypes = {
  className: PropTypes.string,
};


export default ArrowIcon;
