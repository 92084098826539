import clsx from 'clsx';
import PropTypes from 'prop-types';
import styles from './CheckBox.module.scss';

const CheckBox = ({ onChange = () => {}, label = '', checked = false, className = '', checkedBgColor = '', checkColor = '', isDisabled = false }) => {
  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className={clsx(styles.checkbox, 'flex items-center relative', className)}>
      <span
        className={clsx(styles.checkbox__input, checked && styles.checkbox__input_checked)}
        style={checked && checkedBgColor ? { backgroundColor: checkedBgColor } : {}}
      >
        <input checked={checked} name="checkbox" type="checkbox" onChange={onChange} disabled={isDisabled} />
        <span
          className={clsx(styles.checkbox__control, checked && styles.checkbox__control_checked)}
          style={checked && checkedBgColor ? { borderColor: checkedBgColor } : {}}
        >
          <svg aria-hidden="true" focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.73 12.91l6.37 6.37L22.79 4.59"
              fill="none"
              stroke={checkColor || 'currentColor'}
              strokeWidth="3"
            />
          </svg>
        </span>
      </span>
      {label && <span className="text-sm color-black ml-4">{label}</span>}
    </label>
  );
};

CheckBox.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  checked: PropTypes.bool,
  className: PropTypes.string,
  checkColor: PropTypes.string,
  checkedBgColor: PropTypes.string,
  isDisabled: PropTypes.bool,
};


export default CheckBox;
