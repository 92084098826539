import clsx from 'clsx';
import { Link, useMatch, Route, Routes } from 'react-router-dom';

import { Logo } from '@assets/icons';
import { routerType } from '@router/types';
import { CREATE_PROFILE, RESET_MEMORABLE_QUESTION, TERMS_AND_CONDITIONS, PRIVACY_POLICY } from '@router/paths';

const AuthLayout = ({ routes }) => {
  const isCreateProfile = useMatch(CREATE_PROFILE);
  const isResetMemorableQuestion = useMatch(RESET_MEMORABLE_QUESTION);
  const date = new Date();

  const isTermsAndConditions = useMatch(TERMS_AND_CONDITIONS);
  const isPrivacyPolicy = useMatch(PRIVACY_POLICY);

  const renderAuthRoutes = () => (
    <Routes>
    {routes?.map((route, index) => (
      <Route key={index} path={route.path} element={<route.component />} />
    ))}
  </Routes>
  );

  if (isCreateProfile || isTermsAndConditions || isPrivacyPolicy) {
    return <>{renderAuthRoutes()}</>;
  }

  return (
    <div className="h-screen max-h-screen grid grid-cols-2">
      {!isResetMemorableQuestion && (
        <div
          className="h-full w-full flex justify-center items-center relative flex-col"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/img/prescriber-auth-back.png)`,
            backgroundSize: 'cover',
          }}
        >
          <h1 className="text-h1 font-roboto-medium text-white mb-10 max-w-lg">Welcome to our Prescriber Portal</h1>
          <p className="text-md text-white max-w-lg opacity-90">
            Specialist Pharmacy is the first compounding pharmacy of its kind, producing personalised custom-made
            medications for a wide range of medical conditions. We are dedicated to helping prescribers offer
            personalised formulations for more effective treatment, and giving patients access to better medication.
          </p>
          <p className="absolute bottom-7 text-xs text-philippine-silver">
            © {date.getFullYear()} All Rights Reserved. London Specialist Pharmacy Limited
          </p>
        </div>
      )}
      <div className={clsx('h-full w-full flex justify-center items-center', isResetMemorableQuestion && 'col-span-2')}>
        <>
          <div className="absolute top-10 right-11">
            <Logo />
          </div>
          {renderAuthRoutes()}
          <div className="absolute bottom-9 flex flex-row gap-8">
            <Link to={TERMS_AND_CONDITIONS}>
              <span className="text-santas-gray">Terms & Conditions</span>
            </Link>
            <Link to={PRIVACY_POLICY}>
              <span className="text-santas-gray">Privacy Policy</span>
            </Link>
          </div>
        </>
      </div>
    </div>
  );
};

AuthLayout.propTypes = routerType;

export default AuthLayout;
