import PropTypes from 'prop-types';

import TabItem from '../TabItem';

const Tabs = ({ tabs, activeTabValue = '', onChange = () => {} }) => (
  <div className="flex flex-row gap-3">
    {tabs.map(tab => (
      <TabItem
        key={tab.value}
        label={tab.label}
        badgeLabel={tab.badgeLabel}
        LeftIcon={tab.icon}
        isActive={tab.value === activeTabValue}
        onClick={() => onChange(tab)}
      />
    ))}
  </div>
);

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string.isRequired,
      badgeLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }).isRequired,
  ).isRequired,
  activeTabValue: PropTypes.string,
  onChange: PropTypes.func,
};


export default Tabs;
