import { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useRouterPaths } from '@hooks/useRouterPaths';
import { UserContext } from '@contexts/User';
import { ResponsiveFontSizeContext } from '@contexts/ResponsiveFontSize';

import { ToastContainer } from '@components/Modals';
import Root from '@pages/Root';

function App() {
  const { isMobile, isContinueOnMobile } = useContext(ResponsiveFontSizeContext);
  const { profile, isMainLoading, user } = useContext(UserContext);
  const routerPaths = useRouterPaths(profile?.type, user?.status, isMainLoading);

  if (!isContinueOnMobile && isMobile) {
    return <Root.MobileAdaptation />;
  }

  return (
    <>
      <ToastContainer />
      <Routes>
        {routerPaths.map(({ component: Component, routes }, index) => (
          <Route key={index} path="/*" element={<Component routes={routes} />} />
        ))}
      </Routes>
    </>
  );
}

export default App;
