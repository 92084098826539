export const ROOT_PATH = '/';

export const AUTH_SIGN_UP = `${ROOT_PATH}sign-up`;
export const AUTH_SIGN_UP_STEPS = {
  AUTH_SIGN_UP_CHECK_CODE: `${AUTH_SIGN_UP}?check`,
};

export const CREATE_PROFILE = `${ROOT_PATH}create-profile`;
export const AUTH_SIGN_IN = `${ROOT_PATH}`;
export const AUTH_SIGN_IN_STEPS = {
  AUTH_SIGN_IN_CHECK_CODE: `${AUTH_SIGN_IN}?check`,
};

export const AUTH_RESET_PASSWORD = `${ROOT_PATH}reset-password`;
export const AUTH_RESET_PASSWORD_STEPS = {
  AUTH_RESET_PASSWORD_CHECK: `${AUTH_RESET_PASSWORD}?check`,
  AUTH_RESET_PASSWORD_SECURITY_ANSWER: `${AUTH_RESET_PASSWORD}?securityAnswer`,
  AUTH_RESET_PASSWORD_CREATE_PASSWORD: `${AUTH_RESET_PASSWORD}?create`,
};

export const NOT_FOUND = '*';

export const PRESCRIBER = {
  ROOT: `${ROOT_PATH}`,
  HOME: `${ROOT_PATH}home`,
  SETTINGS: `${ROOT_PATH}settings`,
  PRESCRIPTION_HISTORY: `${ROOT_PATH}patients/:id/prescriptions`,
};

export const ADMIN = {
  MEDICATIONS: `${ROOT_PATH}medications`,
  ALLERGIES: `${ROOT_PATH}allergies`,
  DOCTORS: `${ROOT_PATH}doctors`,
  INGREDIENTS: `${ROOT_PATH}ingredients`,
  DOCTOR_INFO: `${ROOT_PATH}doctors/:id`,
  CLINICS: `${ROOT_PATH}clinics`,
};

export const COMMON = {
  PRESCRIPTION: `${ROOT_PATH}prescription/:id`,
};

export const RESET_MEMORABLE_QUESTION = `${ROOT_PATH}reset-security-answer`;

export const TERMS_AND_CONDITIONS = `${ROOT_PATH}terms-and-conditions`;
export const PRIVACY_POLICY = `${ROOT_PATH}privacy-policy`;
