import { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactCalendar from 'react-calendar';
import clsx from 'clsx';
import { getYear, getMonth } from 'date-fns';

import { Select } from '@components/Form';
import { Divider } from '@components/Static';

import styles from './Calendar.module.scss';

const MONTHS = [
  { label: 'January', value: 0 },
  { label: 'February', value: 1 },
  { label: 'March', value: 2 },
  { label: 'April', value: 3 },
  { label: 'May', value: 4 },
  { label: 'June', value: 5 },
  { label: 'July', value: 6 },
  { label: 'August', value: 7 },
  { label: 'September', value: 8 },
  { label: 'October', value: 9 },
  { label: 'November', value: 10 },
  { label: 'December', value: 11 },
];

const YEARS = [];
const currentYear = getYear(Date.now());
for (let year = 1900; year <= currentYear; year += 1) {
  YEARS.push({ value: year, label: year });
}

const Calendar = ({
  value  = new Date(),
  onChange,
  minDate = null,
  maxDate = null,
  withoutShadow = false,
  className = '',
  disabledChange = false,
  ...props
}) => {
  const [selectedYear, setSelectedYear] = useState(YEARS[YEARS.length - 1]);
  const [selectedMonth, setSelectedMonth] = useState(MONTHS[0]);

  useEffect(() => {
    if (!value) {
      return;
    }

    const date = new Date(value);
    const year = YEARS.find(y => y.value === getYear(date));
    const month = MONTHS.find(m => m.value === getMonth(date));

    if (year) {
      setSelectedYear(year);
    }

    if (month) {
      setSelectedMonth(month);
    }
  }, [value]);

  const activeStartDate = useMemo(() => {
    const getFullMonthNumber = value => (value < 10 ? `0${value}` : value);
    return new Date(`${selectedYear.value}-${getFullMonthNumber(selectedMonth.value + 1)}-01`);
  }, [selectedYear, selectedMonth]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-row gap-4 px-6 pt-6 mb-4">
        <Select
          className={clsx('flex-1 bg-ghost-white', styles.width)}
          value={selectedMonth}
          onChange={setSelectedMonth}
          options={MONTHS}
          isSearchable
          placeholder="Month"
        />
        <Select
          className={clsx('flex-1 bg-ghost-white', styles.width)}
          value={selectedYear}
          onChange={setSelectedYear}
          options={YEARS}
          isSearchable
          placeholder="Year"
        />
      </div>
      <Divider />
      <ReactCalendar
        activeStartDate={activeStartDate}
        view="month"
        locale="en-US"
        className={clsx(
          styles['custom-calendar'],
          !withoutShadow && styles['with-shadow'],
          disabledChange && styles.disabledChange,
          className,
        )}
        onChange={onChange}
        value={value}
        prevLabel={null}
        nextLabel={null}
        prev2Label={null}
        next2Label={null}
        minDate={minDate}
        maxDate={maxDate}
        tileClassName={styles['custom-calendar__tile']}
        {...props}
      />
    </div>
  );
};

Calendar.propTypes = {
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  withoutShadow: PropTypes.bool,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  disabledChange: PropTypes.bool,
};


export default Calendar;
