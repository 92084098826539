import loadable from '@loadable/component';
import { loadableRootParams } from '@components/Loaders/Loadable/LoadableAuth';

const Auth = {
  SignIn: loadable(() => import('./SignIn'), loadableRootParams),
  SignUp: loadable(() => import('./SignUp'), loadableRootParams),
  ResetPassword: loadable(() => import('./ResetPassword'), loadableRootParams),
  CreateProfile: loadable(() => import('./CreateProfile'), loadableRootParams),
};

export default Auth;
