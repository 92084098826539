import loadable from '@loadable/component';
import { loadableRootParams } from '@components/Loaders/Loadable/LoadableAuth';

const Admin = {
  Allergies: loadable(() => import('./Allergies'), loadableRootParams),
  Doctors: loadable(() => import('./Doctors'), loadableRootParams),
  Ingredients: loadable(() => import('./Ingredients'), loadableRootParams),
  Medications: loadable(() => import('./Medications'), loadableRootParams),
  DoctorInfo: loadable(() => import('./DoctorInfo'), loadableRootParams),
  Clinics: loadable(() => import('./Clinics'), loadableRootParams),
};

export default Admin;
