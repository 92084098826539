import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './styles/main.scss';

import ResponsiveFontSizeProvider from '@contexts/ResponsiveFontSize';
import UserProvider from './contexts/User';
import ToastProvider from './contexts/Toast';
import PrescriberApp from './App';

const App = PrescriberApp;
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ResponsiveFontSizeProvider>
        <UserProvider>
          <ToastProvider>
            <App />
          </ToastProvider>
        </UserProvider>
      </ResponsiveFontSizeProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
