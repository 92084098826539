import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

function PencilIcon({ className = '', ...props }) {
  return (
    <svg
      className={clsx('w-4 h-4', className)}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.107 6.07L9.928 4.893l-7.761 7.762v1.178h1.178l7.762-7.761zm1.178-1.178l1.178-1.178-1.178-1.178-1.178 1.178 1.178 1.178zM4.035 15.5H.5v-3.536L11.696.767a.833.833 0 011.178 0l2.358 2.358a.833.833 0 010 1.178L4.036 15.5h-.001z"
        fill="#333"
      />
    </svg>
  );
}

PencilIcon.propTypes = {
  className: PropTypes.string,
};


export default PencilIcon;
