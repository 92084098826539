import * as React from 'react';
import PropTypes from 'prop-types';

function IngredientIcon({ fill = 'var(--colors-cadet)', ...props }) {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 24" {...props}>
      <path
        d="M20.333.334c.644 0 1.167.523 1.167 1.167v21c0 .644-.523 1.166-1.167 1.166H4a1.167 1.167 0 0 1-1.167-1.166v-2.334H.5v-2.333h2.333v-2.333H.5v-2.334h2.333v-2.333H.5V8.501h2.333V6.167H.5V3.834h2.333V1.501C2.833.857 3.356.334 4 .334h16.333Zm-1.166 2.333h-14v18.667h14V2.667Zm-5.834 4.667v3.5h3.5v2.333h-3.5v3.5H11l-.001-3.5H7.5v-2.333H11v-3.5h2.333Z"
        fill={fill || 'var(--colors-cadet)'}
      />
    </svg>
  );
}

IngredientIcon.propTypes = {
  fill: PropTypes.string,
};

export default IngredientIcon;
