import clsx from 'clsx';
import PropTypes from 'prop-types';

const ArticleOptions = ({ options = [], className = '' }) => (
  <ul className={clsx('text-base text-dark-charcoal list-disc pl-6 my-2', className)}>
    {options.map(option => (
      <li key={option}>
        <p className="text-base text-dark-charcoal">{option}</p>
      </li>
    ))}
  </ul>
);

ArticleOptions.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
};



export default ArticleOptions;
