import { useMatch, Route, Routes } from 'react-router-dom';

import { routerType } from '@router/types';
import Sidebar from '@components/Sidebar';
import { COMMON, RESET_MEMORABLE_QUESTION, TERMS_AND_CONDITIONS, PRIVACY_POLICY } from '@router/paths';

const RootLayout = ({ routes }) => {
  const isPrescription = useMatch(COMMON.PRESCRIPTION);
  const isTermsAndConditions = useMatch(TERMS_AND_CONDITIONS);
  const isPrivacyPolicy = useMatch(PRIVACY_POLICY);
  const isResetMemorableQuestion = useMatch(RESET_MEMORABLE_QUESTION);

  return (
    <div className="flex flex-row h-full w-full bg-white">
      {!isPrescription && !isResetMemorableQuestion && !isTermsAndConditions && !isPrivacyPolicy && <Sidebar />}
      <Routes>
        {routes?.map((route, index) => (
          <Route key={index} path={route.path} element={<route.component />} />
        ))}
      </Routes>
    </div>
  );
};

RootLayout.propTypes = routerType;

export default RootLayout;
