import PropTypes from 'prop-types';
import clsx from 'clsx';

import styles from './PopupButton.module.scss';

const PopupButton = ({ className = '', children, onClick = () => {} }) => (
  <div className={clsx(styles.btn, className)} onClick={onClick}>
    {children}
  </div>
);

PopupButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
};


export default PopupButton;
