import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Arrow } from '@assets/icons';

const Back = ({ to = '', className = '' }) => { 
  const navigate = useNavigate()

  const backHandler = () => {
    if (to) {
      return navigate(to);
    }

    return navigate(-1);
  };

  return (
    <div
      className={clsx(
        'flex flex-row items-center cursor-pointer hover:opacity-70 transition-opacity duration-30',
        className,
      )}
      onClick={backHandler}
    >
      <div className="mr-2">
        <Arrow className="w-4 h-4" />
      </div>
      <span className="text-md" style={{ color: '#9B9DAE' }}>
        Go Back
      </span>
    </div>
  );
};

Back.propTypes = {
  to: PropTypes.string,
  className: PropTypes.string,
};

export default Back;
