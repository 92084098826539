import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/react';

import styles from './Button.module.scss';

const TypeBtn = ({ option = 'button', ...props }) => {
  if (option === 'link') return <Link {...props} />;
  if (option === 'div') return <div {...props} />;

  return <button type="button" {...props} />;
};

const loaderCssOverride = css`
  width: 1.5rem;
  height: 1.5rem;
`;

const Button = ({
  LeftIcon = null,
  variant = 'default',
  badgeLabel = '',
  className = '',
  isDisabled = false,
  children,
  isLoading = false,
  iconProps,
  ...props
}) => (
  <TypeBtn
    className={clsx(className || 'px-4 py-3', styles[variant], 'flex justify-center items-center')}
    disabled={isDisabled}
    {...props}
  >
    <div className={clsx('flex w-full justify-center items-center', isLoading && 'opacity-0')}>
      {!!LeftIcon && <LeftIcon className="-ml-1 mr-1" {...iconProps} />}
      {children}
      {!!badgeLabel && (
        <div className="ml-2 bg-iguana-green w-6 h-6 rounded-md flex justify-center items-center">
          <span className="text-white">{badgeLabel}</span>
        </div>
      )}
    </div>
    {!!isLoading && (
      <div className="absolute self-center w-6 h-6">
        <ClipLoader color="#ffffff" loading css={loaderCssOverride} />
      </div>
    )}
  </TypeBtn>
);

TypeBtn.propTypes = {
  option: PropTypes.oneOf(['link', 'button', 'div']),
};


Button.propTypes = {
  LeftIcon: PropTypes.func,
  iconProps: PropTypes.shape({}),
  variant: PropTypes.oneOf(['default', 'primary', 'empty', 'danger', 'secondary', 'custom']),
  option: PropTypes.oneOf(['link', 'button', 'div']),
  badgeLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
};


export default Button;
