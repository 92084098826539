import loadable from '@loadable/component';
import { loadableRootParams } from '@components/Loaders/Loadable/LoadableAuth';

const Root = {
  MobileAdaptation: loadable(() => import('./MobileAdaptation'), loadableRootParams),
  PrivacyPolicy: loadable(() => import('./PrivacyPolicy'), loadableRootParams),
  ResetMemorableQuestion: loadable(() => import('./ResetMemorableQuestion'), loadableRootParams),
  TermsAndConditions: loadable(() => import('./TermsAndConditions'), loadableRootParams),
};

export default Root;
