import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const PatientIcon = ({ className = '', ...props }) => (
  <svg className={clsx('w-9 w-12', className)} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.543 12.59c0 5.585-4.499 10.089-10.018 10.089-5.52 0-10.018-4.504-10.018-10.09C7.507 7.004 12.006 2.5 17.525 2.5c5.52 0 10.018 4.504 10.018 10.09Zm2.5 0c0 6.952-5.605 12.589-12.518 12.589S5.007 19.542 5.007 12.589 10.612 0 17.525 0s12.518 5.636 12.518 12.59Zm-.474 23.116a16.003 16.003 0 0 1 4.454 8.794H2.696a16.003 16.003 0 0 1 4.454-8.794 15.807 15.807 0 0 1 11.21-4.67c4.2 0 8.233 1.678 11.209 4.67Zm1.772-1.762A18.505 18.505 0 0 1 36.72 47H0a18.505 18.505 0 0 1 5.377-13.056 18.307 18.307 0 0 1 12.982-5.408c4.87 0 9.54 1.945 12.982 5.408Z"
      fill="#BABAC6"
    />
  </svg>
);

PatientIcon.propTypes = {
  className: PropTypes.string,
};


export default PatientIcon;
