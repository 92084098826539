export const USER_ROLES = {
  PRESCRIBER_ROLE: 'prescriber',
  ADMIN_ROLE: 'admin',
};

export const PROFILE_STATUSES = {
  PENDING: 'pending',
  ACTIVE: 'active',
  DISABLED: 'disabled',
  REJECTED: 'rejected',
};

export const MEDICATION_CATEGORIES = {
  LICENSED: 'licensed',
  UNLICENSED: 'unlicensed',
  VITAMIN: 'vitamin',
};

export const MEDICATION_STATUSES = {
  ACTIVE: 'Active',
  DEACTIVATED: 'Deactivated',
};

export const MEDICATION_STATUS_LABEL_MAP = {
  [MEDICATION_CATEGORIES.LICENSED]: 'Licensed Medicine',
  [MEDICATION_CATEGORIES.UNLICENSED]: 'Unlicensed Medicine',
  [MEDICATION_CATEGORIES.VITAMIN]: 'Vitamin',
};

export const PRACTITIONER_TYPES = [
  { value: 'doctor', label: 'Doctor' },
  { value: 'dentist', label: 'Dentist' },
  { value: 'nurse', label: 'Nurse' },
  { value: 'pharmacist', label: 'Pharmacist' },
  { value: 'physiotherapist', label: 'Physiotherapist' },
  { value: 'optometrist', label: 'Optometrist' },
  { value: 'podiatrist', label: 'Podiatrist' },
  { value: 'paramedic', label: 'Paramedic' },
  { value: 'therapeuticRadiographers', label: 'Therapeutic Radiographers' },
  { value: 'clinicAdmin', label: 'Clinic Administrator' },
];

export const PRESCRIPTION_STATUSES = {
  DRAFT: 'Draft',
  LIVE: 'Live',
  CANCELLED: 'Cancelled',
  DISPENSED: 'Dispensed',
};

export const PRESCRIPTION_STATUS_COLORS = {
  [PRESCRIPTION_STATUSES.DRAFT]: '#ECB956',
  [PRESCRIPTION_STATUSES.LIVE]: '#6AB97A',
  [PRESCRIPTION_STATUSES.CANCELLED]: '#DA6B6F',
  [PRESCRIPTION_STATUSES.DISPENSED]: '#6AB97A',
};

export const MEDICATION_TYPES = {
  COMPOUNDED: 'Compounded Formulation',
  LICENSED: 'Licensed Medicine',
  UNLICENSED: 'Unlicensed Medicine',
  VITAMIN: 'Vitamin',
};

export const COUNTRY_ISO_CODES = {
  UK: 'GB',
};

export const PRESCRIPTION_DEACTIVATION_OPTIONS = {
  ERROR: { value: 'createdInError', label: 'Created in error' },
  NO_NEED: { value: 'noLongerNeeded', label: 'No longer needed' },
  OTHER: { value: 'other', label: 'Other' },
};

export const FORMULATION_TYPES = {
  CAPSULE: 'capsule',
};

export const ORDER_STATUSES = {
  DISPATCHED: 'Dispatched',
  CANCELLED: 'Cancelled',
  IN_PRODUCTION: 'In Production',
};
