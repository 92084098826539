import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const PdfIcon = ({ className = '', ...props }) => (
  <svg
    className={clsx('w-7 h-7', className)}
    fill="none"
    viewBox="0 0 27 27"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.075 26.326a2.703 2.703 0 0 1-2.7-2.7V3.376a2.703 2.703 0 0 1 2.7-2.7h9.85l7.7 7.704v15.246a2.703 2.703 0 0 1-2.7 2.7H6.075Z"
      fill="#D9E4E8"
    />
    <path
      opacity={0.14}
      d="m22.95 6.75-4.665-4.667a3.375 3.375 0 0 0-2.76 3.316v1.35a3.375 3.375 0 0 0 3.375 3.375h4.05V6.75Z"
      fill="#17292D"
    />
    <path d="M18.9 8.1a2.703 2.703 0 0 1-2.7-2.7V.957l7.126 7.145h-4.427Z" fill="#79D3D3" />
    <path
      d="M16.207 0H6.074A3.375 3.375 0 0 0 2.7 3.375v20.25A3.375 3.375 0 0 0 6.074 27h14.85a3.375 3.375 0 0 0 3.375-3.375V8.1L16.207 0Zm5.492 7.425h-2.8A2.027 2.027 0 0 1 16.874 5.4V2.588L21.7 7.425Zm1.25 16.2a2.027 2.027 0 0 1-2.025 2.025H6.074a2.027 2.027 0 0 1-2.025-2.025V3.375A2.027 2.027 0 0 1 6.074 1.35h9.45V5.4A3.375 3.375 0 0 0 18.9 8.775h4.05v14.85Z"
      fill="#333"
    />
    <path
      d="M18.222 19.173c-.017-.17-.181-1.016-1.62-.978-1.015.025-1.479.08-1.66.11-.218-.234-.97-1.067-1.296-1.744.09-.283.444-1.442.425-2.226-.016-.704-.147-1.51-1.018-1.51h-.014c-.255.002-.454.083-.596.24-.225.252-.29.666-.22 1.386.068.685.37 1.458.456 1.673a25.13 25.13 0 0 1-.67 1.828c-.314.752-.549 1.221-.628 1.371-.276.1-1.38.517-1.911 1.024-.496.472-.945 1.033-.528 1.613.14.193.4.31.696.312h.01c.535 0 1.103-.35 1.56-.965.69-.927 1.06-1.558 1.17-1.752.264-.073 1.24-.346 1.572-.428.284-.07.616-.13.767-.158.26.25 1.285 1.16 2.308 1.126.475-.018.818-.143 1.02-.372a.734.734 0 0 0 .18-.551l-.003.001Zm-8.571 2.39a.239.239 0 0 1-.205-.022c-.059-.035-.064-.063-.053-.11.077-.34.908-.915 1.432-1.19-.303.512-.787 1.211-1.174 1.322Zm3.357-8.15c.058 0 .194 0 .2.514.004.345-.13.905-.235 1.287a3.732 3.732 0 0 1-.187-1.096c0-.518.135-.706.22-.706h.002Zm.463 5.22a7.369 7.369 0 0 0-.686.206l.121-.279c.175-.401.35-.884.461-1.202.249.389.522.761.82 1.115a12.8 12.8 0 0 0-.717.16Zm3.62.715c-.303.128-.98-.067-1.554-.509.6-.077 1.378-.097 1.631.113a.179.179 0 0 1 .072.147c0 .129-.048.205-.148.249Z"
      fill="#333"
    />
  </svg>
);

PdfIcon.propTypes = {
  className: PropTypes.string,
};


export default PdfIcon;
