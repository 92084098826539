import * as React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const LogoutIcon = ({ className = '', ...props }) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23 24"
    className={clsx(className, 'w-7 h-7')}
    {...props}
  >
    <path
      d="M3.666 18.999h2.333v2.333h14V2.665H6V5H3.666v-3.5A1.167 1.167 0 0 1 4.833.332h16.333a1.167 1.167 0 0 1 1.167 1.167v21a1.167 1.167 0 0 1-1.167 1.166H4.833A1.167 1.167 0 0 1 3.666 22.5v-3.5Zm2.333-8.167h8.167v2.333H5.999v3.5L.166 12l5.833-4.667v3.5Z"
      fill="var(--colors-santas-gray)"
    />
  </svg>
);

LogoutIcon.propTypes = {
  className: PropTypes.string,
};

export default LogoutIcon;
