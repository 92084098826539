import config from '@config/config';
import { USER_ROLES } from '@utils/consts';

import ApiService from './api';

class ProfileApi extends ApiService {
  constructor() {
    if (ProfileApi.instance) {
      return ProfileApi.instance;
    }

    super(config.accountApiUrl);
    ProfileApi.instance = this;
  }

  async getProfileByUserId(userId) {
    const response = await this.http.get(`/accounts/${this.mainAccountId}/persons?userId=${userId}`);
    return response.data;
  }

  async getOwnPatients() {
    const response = await this.http.get(`/accounts/${this.mainAccountId}/persons?userId=null`);
    return response.data;
  }

  async getProfileById(id) {
    const response = await this.http.get(`/accounts/${this.mainAccountId}/persons/${id}`);
    return response.data;
  }

  async createProfile(userId, data) {
    let profileType = '';
    if (userId) {
      profileType = USER_ROLES.PRESCRIBER_ROLE;
    }

    const response = await this.http.post(`/accounts/${this.mainAccountId}/persons`, {
      accountId: this.mainAccountId,
      userId,
      organizationId: this.mainOrganizationId,
      organizationName: 'Main Organization',
      name: `${data.firstName} ${data.lastName}`,
      type: data.type || profileType,
      profilePictureName: '',
      birthday: data.birthday || null,
      addressType: data.addresses?.length ? 'multiple' : 'common',
      address: data.addresses
        ? data.addresses.map(address => ({
            country: address.country,
            address: address.address,
            postcode: address.postcode,
          }))
        : {
            country: data.country,
            address: data.address,
            postcode: data.postcode,
          },
      additionalData: {
        personalEmail: data.email,
        phone: data.phone,
        practitionerType: data.practitionerType,
        qualificationNumber: data.qualificationNumber,
        clinicEmail: data.clinicEmail,
        clinicName: data.clinicName,
        clinicPhone: data.clinicPhone,
        allergies: data.allergies,
        gender: data.gender,
      },
    });

    return response.data;
  }

  async updateProfileById(id, data) {
    const response = await this.http.put(`/accounts/${this.mainAccountId}/persons/${id}`, data);
    return response.data;
  }

  async getProfilesByType(type) {
    const response = await this.http.get(`/accounts/${this.mainAccountId}/persons?type=${type}`);
    return response.data;
  }

  async getProfilesForDoctorsList() {
    const response = await this.http.get(
      `/accounts/${this.mainAccountId}/persons?type[]=${USER_ROLES.PRESCRIBER_ROLE}&type[]=${USER_ROLES.ADMIN_ROLE}`,
    );
    return response.data;
  }

  async deleteProfileById(id) {
    const response = await this.http.delete(`/accounts/${this.mainAccountId}/persons/${id}`);
    return response.data;
  }
}

const profileApiInstance = new ProfileApi();
export default profileApiInstance;
