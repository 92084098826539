import * as React from 'react';

function CrossIcon({ ...props }) {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
      <path d="M1 1l7 7m0 0l7 7M8 8l7-7M8 8l-7 7" strokeWidth={1.8} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
export default CrossIcon;
