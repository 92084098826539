import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';

import { CheckSecondary } from '@assets/icons';

const color = {
  danger: 'light-carmine-pink',
  success: 'iguana-green',
};

const Toast = ({ message, type = 'success', isOpen = false, onClick = () => {} }) => {
  return (
    <AnimatePresence>
      {!!isOpen && (
        <motion.div
          onClick={onClick}
          className={`pointer-events-auto flex flex-row items-center justify-between p-4 bg-${color[type]} absolute bottom-14 left-1/2 transform -translate-x-1/2 cursor-pointer rounded-lg`}
          initial={{
            left: '50%',
            translateX: '-50%',
            opacity: 0.5,
            y: 126,
            scale: 0,
          }}
          animate={{
            opacity: 1,
            y: 0,
            scale: 1,
            transition: { type: 'spring', duration: 0.3, stiffness: 700, damping: 30 },
          }}
          exit={{
            opacity: 0,
            y: 40,
            transition: { duration: 0.3 },
          }}
        >
          <CheckSecondary className="w-6 h-6" />
          <span className="text-white text-md ml-5 mr-24">{message}</span>
          <span className="text-white text-md opacity-50">Close</span>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

Toast.propTypes = {
  message: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.string,
  isOpen: PropTypes.bool,
};


export default Toast;
