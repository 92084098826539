import * as React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

function VerticalDots({ className = '', ...props }) {
  return (
    <svg
      className={clsx('w-6 h-6', className)}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 3c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 14c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-7c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
        fill="#C4C4C4"
      />
    </svg>
  );
}

VerticalDots.propTypes = {
  className: PropTypes.string,
};


export default VerticalDots;
