import Modal from 'react-modal';
import { Cross } from '@assets/icons';
import { Button } from '@components/Form';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import clsx from 'clsx';

const customStyles = {
  content: {
    width: '100%',
    height: '100%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    padding: 0,
    overflow: 'initial',
    background: 'transparent',
  },
  overlay: {
    zIndex: 30,
    background: 'transparent',
    width: '100%',
    height: '100%',
  },
};

const CustomModal = ({ openModal, setOpenModal, className = '', children }) => (
  <AnimatePresence>
    {openModal && (
      <Modal isOpen={openModal} ariaHideApp={false} style={customStyles} onRequestClose={() => setOpenModal(false)}>
        <motion.div
          className="bg-black w-full h-full flex justify-center items-center bg-opacity-40"
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: {
              type: 'easyInOut',
              stiffness: 300,
            },
          }}
          exit={{
            opacity: 0,
            transition: { duration: 0.4 },
          }}
        >
          <motion.div
            className="relative z-30"
            initial={{
              opacity: 0,
              y: 60,
              scale: 0.5,
            }}
            animate={{
              opacity: 1,
              y: 0,
              scale: 1,
            }}
            exit={{
              opacity: 0,
              scale: 0.5,
              transition: { duration: 0.6 },
            }}
          >
            <div className={clsx('shadow', className)}>
              <Button
                className="absolute right-8 top-8 w-3.5 h-3.5"
                variant="custom"
                onClick={() => setOpenModal(false)}
              >
                <Cross className="cursor-pointer stroke-current stroke-silver-sand w-4 h-4" />
              </Button>
              {children}
            </div>
          </motion.div>
        </motion.div>
      </Modal>
    )}
  </AnimatePresence>
);

CustomModal.propTypes = {
  className: PropTypes.string,
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default CustomModal;
