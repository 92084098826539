import * as React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

function PlusIcon({ className = '', ...props }) {
  return (
    <svg
      className={clsx('w-3.5 3.5', className)}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M6 6V0h2v6h6v2H8v6H6V8H0V6h6z" fill="var(--colors-iguana-green" />
    </svg>
  );
}

PlusIcon.propTypes = {
  className: PropTypes.string,
};


export default PlusIcon;
