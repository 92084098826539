import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import clsx from 'clsx';

const customStyles = {
  content: {
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    border: 'none',
    padding: 0,
    overflow: 'initial',
    background: 'transparent',
  },
  overlay: {
    zIndex: 30,
    background: 'transparent',
    width: '100%',
    height: '100%',
    padding: 0,
  },
};

// For now it works only as right panel
// TODO: Add left panel
const SidePanel = ({ openModal, setOpenModal, className = '', children }) => (
  <AnimatePresence>
    {openModal && (
      <Modal isOpen={openModal} ariaHideApp={false} style={customStyles} onRequestClose={() => setOpenModal(false)}>
        <motion.div
          className="bg-black w-full h-full flex justify-end items-center bg-opacity-40"
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: {
              type: 'easyInOut',
              stiffness: 300,
            },
          }}
          exit={{
            opacity: 0,
            transition: { duration: 0.4 },
          }}
        >
          <motion.div
            className="relative z-30"
            initial={{
              opacity: 0,
              x: '100%',
            }}
            animate={{
              opacity: 1,
              x: 0,
              transition: { duration: 0.6 },
            }}
            exit={{
              opacity: 0,
              x: '100%',
              transition: { duration: 0.6 },
            }}
          >
            <div className={clsx('shadow', className)}>{children}</div>
          </motion.div>
        </motion.div>
      </Modal>
    )}
  </AnimatePresence>
);

SidePanel.propTypes = {
  className: PropTypes.string,
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};


export default SidePanel;
