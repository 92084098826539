import Skeleton from 'react-loading-skeleton';

export const loadableRootParams = {
  fallback: (
    <Skeleton
      containerClassName="h-screen w-screen"
      style={{
        width: '100vw',
        height: '100%',
      }}
    />
  ),
};
