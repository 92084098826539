import * as React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

function SmallArrow({ className = '', ...props }) {
  return (
    <svg className={clsx('w-4 h-2', className)} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M7 2.828l4.95 4.95 1.414-1.414L7 0 .636 6.364 2.05 7.778 7 2.828z" fill="#C4C4C4" />
    </svg>
  );
}

SmallArrow.propTypes = {
  className: PropTypes.string,
};


export default SmallArrow;
