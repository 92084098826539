import { useContext } from 'react';

import { ToastContext } from '@contexts/Toast';
import Toast from './Toast';

const ToastContainer = () => {
  const { isOpen, message, close, type } = useContext(ToastContext);

  return (
    <div className="w-screen h-screen absolute top-0 left-0 overflow-hidden pointer-events-none z-50">
      <Toast isOpen={isOpen} message={message} onClick={close} type={type} />
    </div>
  );
};

export default ToastContainer;
