import { useState, useCallback, useEffect, useRef, useMemo, useContext } from 'react';
import { useTable } from 'react-table';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';

import { ResponsiveFontSizeContext } from '@contexts/ResponsiveFontSize';

import './Table.scss';

const getFullColumnsWidth = cols => {
  return cols.reduce((prev, col) => {
    if (col !== null && typeof col === 'object' && 'offestWidth' in col) {
      return prev + col.offsetWidth;
    }
    return prev;
  }, 0);
};

const Table = ({
  columns,
  minCellWidth = 60,
  data = [],
  rightHeader = null,
  templateColumns = '',
  onRowClick = () => {},
  selectedRow = null,
  compactHeaders = false,
  variant = '',
  disableResize = false,
  verticalSeparator = true,
  headerBottomBorder = true,
}) => {
  const { htmlFontSize } = useContext(ResponsiveFontSizeContext);
  const [tableHeight, setTableHeight] = useState(`${80 / htmlFontSize}rem`);
  const [activeIndex, setActiveIndex] = useState(null);
  const tableElement = useRef(null);
  const columnsRef = useRef([]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });
  const { key, ...headerGroupProps } = headerGroups[0].getHeaderGroupProps();
  const { key: tableKey, ...tableBodyProps } = getTableBodyProps();
  useEffect(() => {
    setTableHeight(`${tableElement.current.offsetHeight / htmlFontSize}rem`);
  }, [htmlFontSize]);

  const mouseDown = index => {
    setActiveIndex(index);
  };

  const mouseMove = useCallback(
    e => {
      const initialWidth = getFullColumnsWidth(columnsRef.current);
      const gridColumns = columns.map((__col, i) => {
        if (i === activeIndex) {
          const diff = initialWidth - columnsRef.current[i].offsetWidth;
          const width = e.clientX - columnsRef.current[i].offsetLeft;

          if (width >= minCellWidth && diff + width >= tableElement.current.offsetWidth) {
            return `${width / htmlFontSize}rem`;
          }
        }
        return `${columnsRef.current[i].offsetWidth / htmlFontSize}rem`;
      });

      tableElement.current.style.gridTemplateColumns = `${gridColumns.join(' ')}`;
    },
    [activeIndex, columns, minCellWidth, htmlFontSize],
  );

  const removeListeners = useCallback(() => {
    window.removeEventListener('mousemove', mouseMove);
    window.removeEventListener('mouseup', removeListeners);
  }, [mouseMove]);

  const mouseUp = useCallback(() => {
    setActiveIndex(null);
    removeListeners();
  }, [setActiveIndex, removeListeners]);

  useEffect(() => {
    if (activeIndex !== null) {
      window.addEventListener('mousemove', mouseMove);
      window.addEventListener('mouseup', mouseUp);
    }

    return () => {
      removeListeners();
    };
  }, [activeIndex, mouseMove, mouseUp, removeListeners]);

  const initialHeaderStyle = useMemo(() => {
    return data.length ? {} : { borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderBottom: 'none' };
  }, [data]);

  return (
    <div className={variant === "prescriptionMedications" ? "": "container"}>
      <div className={clsx('table-wrapper', variant)} style={initialHeaderStyle}>
        <table
          {...getTableProps()}
          className={clsx(
            'resizeable-table',
            'commonTable',
            verticalSeparator && 'verticalSeparator',
            !templateColumns && `grid-cols-${columns.length}`,
            compactHeaders && 'compactTable',
            headerBottomBorder && 'headerBottomBorder',
            variant,
          )}
          style={templateColumns ? { gridTemplateColumns: templateColumns } : {}}
          ref={tableElement}
        >
          <thead {...headerGroupProps} key={key}>
            <tr>
              {headerGroups[0].headers.map((column, i) => (
                <th
                  ref={el => {
                    columnsRef.current[i] = el;
                  }}
                  key={i} // eslint-disable-line react/no-array-index-key
                >
                  {column.render('Header')}
                  {!disableResize && (
                    <div
                      style={{ height: tableHeight }}
                      onMouseDown={() => mouseDown(i)}
                      className={`resize-handle ${activeIndex === i ? 'active' : 'idle'}`}
                    />
                  )}
                  {!!rightHeader && i === headerGroups[0].headers.length - 1 && (
                    <div className="absolute top-6 right-6">{rightHeader}</div>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody {...tableBodyProps} key={tableKey}>
            {rows.map((row, i) => {
              prepareRow(row);

              const isSelected = selectedRow && row.original.id && selectedRow?.id === row.original.id && 'bg-black';

              return (
                <tr
                  className="h-20 flex justify-center items-center cursor-pointer"
                  onClick={() => onRowClick(row)}
                  key={i} // eslint-disable-line react/no-array-index-key
                >
                  {row.cells.map((cell, j) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={j} // eslint-disable-line react/no-array-index-key
                        className={clsx(
                          'px-8 w-full h-full flex items-center overflow-x-auto transition-colors duration-300',
                          isSelected && 'bg-anti-flash-white',
                          j === 0 && 'relative',
                        )}
                      >
                        <AnimatePresence>
                          {isSelected && j === 0 && (
                            <motion.div
                              className={clsx('bg-iguana-green absolute left-0 h-full w-1')}
                              initial={{
                                opacity: 0,
                                scaleY: 0,
                              }}
                              animate={{
                                opacity: 1,
                                scaleY: 1,
                                transition: { duration: 0.3 },
                              }}
                              exit={{
                                opacity: 0,
                                scaleY: 0,
                                transition: { duration: 0.3 },
                              }}
                            />
                          )}
                        </AnimatePresence>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

Table.propTypes = {
  minCellWidth: PropTypes.number,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string,
      accessor: PropTypes.string,
    }),
  ).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.arrayOf(PropTypes.any),
  selectedRow: PropTypes.shape({
    id: PropTypes.string,
  }),
  rightHeader: PropTypes.node,
  templateColumns: PropTypes.string,
  onRowClick: PropTypes.func,
  compactHeaders: PropTypes.bool,
  variant: PropTypes.string,
  disableResize: PropTypes.bool,
  verticalSeparator: PropTypes.bool,
  headerBottomBorder: PropTypes.bool,
};

export default Table;
