import clsx from 'clsx';
import PropTypes from 'prop-types';

const Divider = ({ className = '', isVertical = false }) => (
  <div className={clsx(isVertical ? 'h-full w-px' : 'w-full h-px', className)} style={{ background: '#DDDDDD' }} />
);

Divider.propTypes = {
  className: PropTypes.string,
  isVertical: PropTypes.bool,
};


export default Divider;
