import { useCallback, useEffect, useMemo, useState } from 'react';

import GeolocationService from '@services/api/geolocation';

export const useAutocomplete = () => {
  const search = useCallback(async (countryISO, text, containerId) => {
    const result = await GeolocationService.getAutocomplete(countryISO, text, containerId);
    return result;
  }, []);

  return useMemo(() => {
    return { search };
  }, [search]);
};

export const useAddressInfo = (addressId, countryISO, callback) => {
  const [addressInfo, setAddressInfo] = useState(null);

  useEffect(() => {
    const fetchAddressInfoList = async () => {
      if (!addressId) {
        return;
      }

      const response = await GeolocationService.getAddressInfo(addressId, countryISO);
      setAddressInfo(response);

      if (callback && typeof callback === 'function') {
        callback(response);
      }
    };
    fetchAddressInfoList();
  }, [addressId]);

  return useMemo(() => {
    if (!addressId) {
      return null;
    }

    return addressInfo;
  }, [addressId, addressInfo]);
};
