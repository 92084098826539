const config = {
  authApiUrl: process.env.REACT_APP_AUTH_API_URL,
  accountApiUrl: process.env.REACT_APP_ACCOUNT_API_URL,
  medicationApiUrl: process.env.REACT_APP_MEDICATION_API_URL,
  geolocationApiUrl: process.env.REACT_APP_GEOLOCATION_API_URL,
  prescriptionApiUrl: process.env.REACT_APP_PRESCRIPTION_API_URL,
  prescriberHelpdeskUrl: process.env.REACT_APP_PRESCRIBER_HELPDESK_URL,
  isPatientApp: process.env.REACT_APP_TYPE === 'patient',
};

export default config;
