import PropTypes from 'prop-types';
import clsx from 'clsx';

const TabItem = ({ label = '', badgeLabel = '', isActive = false, onClick = () => {}, className = '', LeftIcon= null }) => {
  return (
    <div
      className={clsx(
        'p-2 rounded-md cursor-pointer transition-all duration-200 flex flex-row items-center',
        isActive ? 'bg-anti-flash-white' : 'hover:bg-anti-flash-white hover:bg-opacity-50',
        className,
      )}
      onClick={onClick}
    >
      {!!LeftIcon && <LeftIcon />}
      <span
        className={clsx(
          'font-roboto-medium transition-colors duration-200',
          isActive ? 'text-dark-charcoal' : 'text-silver-sand',
        )}
      >
        {label}
      </span>
      {!!badgeLabel && (
        <div className="ml-2 bg-iguana-green w-6 h-6 rounded-md flex justify-center items-center">
          <span className="text-white">{badgeLabel}</span>
        </div>
      )}
    </div>
  );
};

TabItem.propTypes = {
  label: PropTypes.string,
  badgeLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  LeftIcon: PropTypes.func,
};


export default TabItem;
