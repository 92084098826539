import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const HomeIcon = ({ className = '', fill = 'var(--colors-cadet)', ...props }) => (
  <svg
    viewBox="0 0 28 29"
    className={clsx('w-7 h-7', className)}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.5 25.501h9V11.968L14 3.802 3.5 11.968v13.533h9v-9h3v9Zm12 1.5a1.5 1.5 0 0 1-1.5 1.5H2a1.5 1.5 0 0 1-1.5-1.5V11.236a1.5 1.5 0 0 1 .579-1.185l12-9.333a1.5 1.5 0 0 1 1.842 0l12 9.333a1.5 1.5 0 0 1 .579 1.185v15.765Z"
      fill={fill || 'var(--colors-cadet)'}
    />
  </svg>
);

HomeIcon.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string,
};


export default HomeIcon;
