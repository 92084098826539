import { createContext, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { jwtDecode } from 'jwt-decode';
import AuthService from '@services/api/auth';
import ProfileService from '@services/api/profile';
import UserService from '@services/api/user';
import LocalStorageService from '@services/localStorage';
import { ROOT_PATH, CREATE_PROFILE as PRESCRIBER_CREATE_PROFILE } from '@router/paths';
import { PROFILE_STATUSES } from '@utils/consts';

const CREATE_PROFILE = PRESCRIBER_CREATE_PROFILE;

export const UserContext = createContext({
  user: null,
  profile: null,
  isMainLoading: true,
  token: null,
  login: async () => {},
  logout: async () => {},
  register: async () => {},
  checkEmail: async () => {},
  forgotPassword: async () => {},
  resetPassword: async () => {},
  deactivateCurrentUser: () => {},
  refetchUserData: async () => {},
  updateEmail: async () => {},
  impersonate: async () => {},
});

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [token, setToken] = useState({});

  const [isMainLoading, setIsMainLoading] = useState(true);

  const navigate = useNavigate();

  const setProfileFromUser = useCallback(async userData => {
    const profileResponse = await ProfileService.getProfileByUserId(userData.id);
    setProfile(profileResponse);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsMainLoading(true);

      const accessToken = LocalStorageService.getAccessToken();
      if (!accessToken) {
        setIsMainLoading(false);
        return;
      }

      try {
        let userResponse;

        try {
          userResponse = await AuthService.getCurrentUser();
          setUser(userResponse);
        } catch (err) {
          // Try to refresh token if current one is invalid
          const result = await AuthService.refreshToken();

          LocalStorageService.setAccessToken(result.accessToken);
          LocalStorageService.setRefreshToken(result.refreshToken);

          // Set current user after token refresh
          userResponse = await AuthService.getCurrentUser();
          setUser(userResponse);
        }

        try {
          await setProfileFromUser(userResponse);
          if (userResponse?.status === PROFILE_STATUSES.REJECTED) {
            navigate(CREATE_PROFILE);
          }
        } catch (err) {
          navigate(CREATE_PROFILE);
        }
      } catch (err) {
        LocalStorageService.removeAccessToken();
        LocalStorageService.removeRefreshToken();
      } finally {
        setIsMainLoading(false);
      }
    };

    fetchData();
  }, [setUser, navigate, setProfileFromUser]);

  const checkEmail = useCallback(async email => {
    const result = await AuthService.checkEmail(email);
    return result.isExist;
  }, []);

  const login = useCallback(
    async (email, password, otp) => {
      const result = await AuthService.emailLogin(email, password, otp);
      if (!result?.accessToken) {
        return false;
      }

      LocalStorageService.setAccessToken(result.accessToken);
      LocalStorageService.setRefreshToken(result.refreshToken);

      const userResponse = await AuthService.getCurrentUser();
      setUser(userResponse);

      try {
        await setProfileFromUser(userResponse);

        // If profile was rejected - redirect user to create profile page
        if (userResponse?.status === PROFILE_STATUSES.REJECTED) {
          navigate(CREATE_PROFILE);
          return true;
        }
      } catch (err) {
        // If profile is not created yet - redirect to create profile page
        navigate(CREATE_PROFILE);
        return true;
      }

      navigate(ROOT_PATH);
      return true;
    },
    [navigate, setProfileFromUser],
  );

  const register = useCallback(
    async (email, password, token, userCallback) => {
      const result = await AuthService.emailRegistration(email, password, token);
      if (!result?.accessToken) {
        return false;
      }

      LocalStorageService.setAccessToken(result.accessToken);
      LocalStorageService.setRefreshToken(result.refreshToken);

      const userResponse = await AuthService.getCurrentUser();
      setUser(userResponse);

      if (userCallback && typeof userCallback === 'function') {
        await userCallback(userResponse);
      }

      navigate(CREATE_PROFILE);
      return true;
    },
    [navigate],
  );

  const updateEmail = useCallback(async (newEmail, user) => {
    const result = await AuthService.updateEmail(newEmail, user);
    return result;
  }, []);

  const forgotPassword = useCallback(async (email, answer) => {
    const result = await AuthService.forgotPassword(email, answer);
    return result;
  }, []);

  const resetPassword = useCallback(
    async (newPassword, token) => {
      const result = await AuthService.resetPassword(newPassword, token);

      LocalStorageService.setAccessToken(result.accessToken);
      LocalStorageService.setRefreshToken(result.refreshToken);

      const userResponse = await AuthService.getCurrentUser();
      setUser(userResponse);

      try {
        await setProfileFromUser(userResponse);

        // If profile was rejected - redirect user to create profile page
        if (userResponse?.status === PROFILE_STATUSES.REJECTED) {
          navigate(CREATE_PROFILE);
          return;
        }
      } catch (err) {
        // If profile is not created yet - redirect to create profile page
        navigate(CREATE_PROFILE);
        return;
      }

      navigate(ROOT_PATH);
    },
    [navigate, setProfileFromUser],
  );

  const refetchUserData = useCallback(async () => {
    const accessToken = LocalStorageService.getAccessToken();
    if (!accessToken) {
      return;
    }

    try {
      let userResponse;

      try {
        userResponse = await AuthService.getCurrentUser();
        setUser(userResponse);
      } catch (err) {
        // Try to refresh token if current one is invalid
        const result = await AuthService.refreshToken();

        LocalStorageService.setAccessToken(result.accessToken);
        LocalStorageService.setRefreshToken(result.refreshToken);

        // Set current user after token refresh
        userResponse = await AuthService.getCurrentUser();
        setUser(userResponse);
      }

      try {
        await setProfileFromUser(userResponse);

        if (userResponse?.status === PROFILE_STATUSES.REJECTED) {
          navigate(CREATE_PROFILE);
          return;
        }
      } catch (err) {
        navigate(CREATE_PROFILE);
        return;
      }

      navigate(ROOT_PATH);
    } catch (err) {
      LocalStorageService.removeAccessToken();
      LocalStorageService.removeRefreshToken();

      navigate(ROOT_PATH);
    }
  }, [navigate, setProfileFromUser]);

  const logout = useCallback(async () => {
    await AuthService.logout();

    LocalStorageService.removeAccessToken();
    LocalStorageService.removeRefreshToken();

    setUser(null);
    setProfile(null);

    navigate(ROOT_PATH);
  }, [navigate]);

  const updateCurrentProfile = useCallback(
    async profileData => {
      const newProfile = {
        ...profile,
        ...profileData,
      };

      await ProfileService.updateProfileById(profile.id, newProfile);

      if (newProfile.birthday) {
        const [year, month, day] = newProfile.birthday.split('-');
        newProfile.birthday = new Date(year, month + 1, day).toISOString();
      }

      setProfile(newProfile);
    },
    [profile],
  );

  const updateCurrentUserPhone = useCallback(
    async phone => {
      const newUser = {
        ...user,
        phone,
      };

      await UserService.updateUserById(user.id, newUser);
      setUser(newUser);
    },
    [user],
  );

  const deactivateCurrentUser = useCallback(() => {
    setUser(prevUser => ({
      ...prevUser,
      status: PROFILE_STATUSES.DISABLED,
    }));
  }, []);

  const impersonateUser = useCallback(
    async userId => {
      const result = await AuthService.impersonate(userId);
      console.log(result);
      if (!result.accessToken) {
        return false;
      }
      const decodedToken = jwtDecode(result.accessToken);

      LocalStorageService.setAccessToken(result.accessToken);
      LocalStorageService.setRefreshToken(result.refreshToken);

      const userResponse = await AuthService.getCurrentUser();
      setUser(userResponse);
      setToken(decodedToken);

      navigate(ROOT_PATH);
      console.log(decodedToken);
      console.log(decodedToken.isImpersonating);
      return true;
    },
    [navigate],
  );

  const value = {
    user,
    isMainLoading,
    profile,
    checkEmail,
    token,
    login,
    logout,
    register,
    forgotPassword,
    resetPassword,
    refetchUserData,
    updateCurrentProfile,
    updateCurrentUserPhone,
    deactivateCurrentUser,
    updateEmail,
    impersonateUser,
  };

  return <UserContext.Provider value={{ ...value }}>{children}</UserContext.Provider>;
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserProvider;
