import { ADMIN, NOT_FOUND, COMMON } from '@router/paths';
import Admin from '@pages/Admin';
import { RedirectRoot } from '@pages/RedirectRoot';
import Common from '@pages/Common';
import { Medication, Ingredient, Allergy, DoctorMask, Home } from '@assets/icons';

export const ADMIN_ROUTES = [
  {
    name: 'Medications',
    path: ADMIN.MEDICATIONS,
    exact: true,
    component: Admin.Medications,
    isMenu: true,
    menuIcon: Medication,
  },
  {
    name: 'Compounded Ingredients',
    path: ADMIN.INGREDIENTS,
    exact: true,
    component: Admin.Ingredients,
    isMenu: true,
    menuIcon: Ingredient,
  },
  {
    name: 'Allergies',
    path: ADMIN.ALLERGIES,
    exact: true,
    component: Admin.Allergies,
    isMenu: true,
    menuIcon: Allergy,
  },
  {
    name: 'Users',
    path: ADMIN.DOCTORS,
    exact: true,
    component: Admin.Doctors,
    isMenu: true,
    menuIcon: DoctorMask,
  },
  {
    name: 'Clinics',
    path: ADMIN.CLINICS,
    component: Admin.Clinics,
    exact: true,
    isMenu: true,
    menuIcon: Home,
  },
  {
    name: 'Prescription',
    path: COMMON.PRESCRIPTION,
    exact: true,
    component: Common.Prescription,
  },
  {
    name: 'DoctorInfo',
    path: ADMIN.DOCTOR_INFO,
    exact: true,
    component: Admin.DoctorInfo,
  },
  {
    path: NOT_FOUND,
    component: () => <RedirectRoot path={ADMIN.MEDICATIONS} />,
  },
];
