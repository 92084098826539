import loadable from '@loadable/component';
import { loadableRootParams } from '@components/Loaders/Loadable/LoadableAuth';

const Prescriber = {
  ConfirmationPending: loadable(() => import('./ConfirmationPending'), loadableRootParams),
  Home: loadable(() => import('./Home'), loadableRootParams),
  Settings: loadable(() => import('./Settings'), loadableRootParams),
  PrescriptionHistory: loadable(() => import('./PrescriptionHistory'), loadableRootParams),
};

export default Prescriber;
