import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';

import { useRouterPaths } from '@hooks/useRouterPaths';
import { ROOT_PATH, TERMS_AND_CONDITIONS, PRIVACY_POLICY } from '@router/paths';
import { USER_ROLES } from '@utils/consts';
import AccountIcon from '@assets/icons/AccountIcon';
import { UserContext } from '@contexts/User';
import { LogoIcon, Logout, Terms, Policy } from '@assets/icons';
import { CustomModal } from '@components/Modals';
import { Button } from '@components/Form';

import styles from './Sidebar.module.scss';

const SidebarItem = React.memo(({ isWide = false, isActive = false, item }) => {
  return (
    <Link
      key={item.name}
      className={clsx(
        'flex flex-row items-center py-4 relative h-14',
        isWide ? 'pl-6' : 'w-full flex justify-center pr-1 mb-2',
      )}
      to={item.path}
    >
      <AnimatePresence>
        {isActive && (
          <motion.div
            className={clsx('absolute left-0 h-full w-1 rounded-r-sm')}
            style={{ background: '#5BC67F' }}
            initial={{
              opacity: 0,
              scaleY: 0,
            }}
            animate={{
              opacity: 1,
              scaleY: 1,
              transition: { duration: 0.3 },
            }}
            exit={{
              opacity: 0,
              scaleY: 0,
              transition: { duration: 0.3 },
            }}
          />
        )}
      </AnimatePresence>
      {item.menuIcon && (
        <div className="w-9 flex items-center justify-center">
          <item.menuIcon className="w-7 h-7" fill={isActive ? 'var(--colors-cadet)' : 'var(--colors-santas-gray)'} />
        </div>
      )}
      {isWide && (
        <span className={clsx('ml-3 text-base', isActive ? 'text-cadet' : 'text-santas-gray')}>{item.name}</span>
      )}
    </Link>
  );
});

SidebarItem.propTypes = {
  isWide: PropTypes.bool,
  isActive: PropTypes.bool,
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    path: PropTypes.string,
    menuIcon: PropTypes.func,
  }).isRequired,
};

const Sidebar = () => {
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

  const { profile, user, logout } = useContext(UserContext);
  const { pathname } = useLocation();

  const [active, setActive] = useState();
  const menuItems = useRouterPaths(profile?.type, user?.status)[0].routes.filter(item => item?.isMenu);
  const isWide = profile?.type === USER_ROLES.ADMIN_ROLE;

  return (
    <>
      <motion.div
        className="bg-alabaster flex items-start flex-col border-r border-bright-gray"
        onClick={() => setActive(!active)}
      >
        <AnimatePresence>
          <div className={clsx('flex flex-col h-full', isWide && 'w-72')}>
            <div className="px-4 py-8 border-b border-bright-gray flex items-center mb-6">
              <Link to={ROOT_PATH}>
                <LogoIcon />
              </Link>
              {isWide && (
                <span className="text-white ml-3 cursor-default" style={{ color: '#0963AE' }}>
                  specialist pharmacy
                </span>
              )}
            </div>
            {menuItems.map(item => {
              const isActive = pathname && pathname.startsWith(item.path);
              return <SidebarItem key={item.name} isWide={isWide} isActive={isActive} item={item} />;
            })}
            <div className={clsx('flex flex-row justify-start items-center mt-auto px-8', !isWide && 'self-center')}>
              <Link to={TERMS_AND_CONDITIONS} className="flex flex-row items-center">
                <Terms className="w-7 h-7" />
                {isWide && <span className="text-santas-gray ml-3">Terms and Conditions</span>}
              </Link>
            </div>
            <div className={clsx('flex flex-row justify-start items-center mt-8 px-8', !isWide && 'self-center')}>
              <Link to={PRIVACY_POLICY} className="flex flex-row items-center">
                <Policy className="w-7 h-7" />
                {isWide && <span className="text-santas-gray ml-3">Privacy Policy</span>}
              </Link>
            </div>
            <div className={clsx(isWide ? 'justify-between items-center' : 'self-center', 'px-4 py-8 flex')}>
              <div className="flex flex-row justify-start items-center">
                <div
                  className="bg-white w-14 h-14 rounded-full items-center p-5 flex"
                  style={{ background: '#F0F0F2' }}
                >
                  <AccountIcon className="opacity-60 w-5 h-6" />
                </div>
                {isWide && <span className="text-santas-gray text-lg ml-4 cursor-default">{profile.name}</span>}
              </div>
              {isWide && (
                <div className="cursor-pointer" onClick={() => setIsLogoutModalOpen(true)}>
                  <Logout />
                </div>
              )}
            </div>
            {!isWide && (
              <div className="cursor-pointer flex self-center mb-8" onClick={() => setIsLogoutModalOpen(true)}>
                <Logout />
              </div>
            )}
          </div>
        </AnimatePresence>
      </motion.div>
      <CustomModal openModal={isLogoutModalOpen} setOpenModal={setIsLogoutModalOpen} className={styles.logoutModal}>
        <div className="p-8 bg-white rounded-md">
          <h4 className="text-xl text-dark-charcoal font-roboto-medium mb-3">Log out</h4>
          <p className="text-davy-grey mb-10">Are you sure you want to log out from your account?</p>
          <div className="flex flex-row justify-end">
            <Button onClick={() => setIsLogoutModalOpen(false)} className="px-6 py-3 mr-6" variant="empty">
              <span>Cancel</span>
            </Button>
            <Button onClick={() => logout()} variant="danger" className="px-6 py-3">
              <span>Log out</span>
            </Button>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default Sidebar;
