import { createContext, useState, useMemo, useEffect } from 'react';
import { isMobile as isMobileDevice } from 'react-device-detect';
import PropTypes from 'prop-types';

import useResize from '@hooks/useResize';

export const ResponsiveFontSizeContext = createContext({
  isContinueOnMobile: false,
  isMobile: false,
  htmlFontSize: 16,
  setHtmlFontSize: () => {},
  setIsContinueOnMobile: () => {},
});

const ResponsiveFontSizeProvider = ({ children }) => {
  const [isContinueOnMobile, setIsContinueOnMobile] = useState(false);

  const [isMobile, setIsMobile] = useState(false);
  const [htmlFontSize, setHtmlFontSize] = useState(16);

  const { windowWidth } = useResize();

  useEffect(() => {
    document.documentElement.style.fontSize = `${htmlFontSize}px`;
  }, [htmlFontSize]);

  useEffect(() => {
    if (windowWidth < 1280) {
      setHtmlFontSize(12);
    } else if (windowWidth < 1601) {
      setHtmlFontSize(14);
    } else {
      setHtmlFontSize(16);
    }
  }, [windowWidth]);

  useEffect(() => {
    if (!windowWidth || isContinueOnMobile) {
      return;
    }

    setIsMobile(isMobileDevice && windowWidth < 900);
  }, [windowWidth, isContinueOnMobile]);

  const value = useMemo(
    () => ({
      isContinueOnMobile,
      isMobile,
      htmlFontSize,
      setHtmlFontSize,
      setIsContinueOnMobile,
    }),
    [isContinueOnMobile, isMobile, htmlFontSize],
  );

  return <ResponsiveFontSizeContext.Provider value={value}>{children}</ResponsiveFontSizeContext.Provider>;
};

ResponsiveFontSizeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ResponsiveFontSizeProvider;
