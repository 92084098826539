import PropTypes from 'prop-types';
import clsx from 'clsx';

function EyeIcon({ className = '', ...props }) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx('w-5 h-5', className)}
      viewBox="0 0 21 21"
      {...props}
    >
      <circle cx={10.5} cy={10.5} r={2.5} fill="#CACACA" />
      <path
        d="M2.625 10.5c4.375-7 11.375-7 15.75 0M2.625 10.5c4.375 7 11.375 7 15.75 0"
        stroke="#CACACA"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

EyeIcon.propTypes = {
  className: PropTypes.string,
};


export default EyeIcon;
